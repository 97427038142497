const doc = document

/* Results collapse
* ------------------------->
* - toggle class `is-open` on closest <tr> element
*/

document.addEventListener("click", (event) => {
	if (event.target && event.target.classList.contains('js-collapse')) {
		var parent = event.target.closest('tr')

		parent.classList.toggle('is-open')
	}
})


/* Time
* ------------------------->
*/

const currentTime = doc.getElementsByClassName('js-current-time')

setInterval(function() {
    const timeParts = (new Date()).toTimeString().split(':', 3)
    Array.from(currentTime).forEach(function(element) {
    	const hours = timeParts[0].replace(/^0/, '');
    	element.textContent = hours + ":" + timeParts[1];
    })
}, 1000);


/* Hide flash messages
* ------------------------->
*/

setTimeout(function() {
	const flash = document.querySelector('.flash')
	if (flash) {
		flash.style.display = 'none';
	}
}, 5 * 1000);

/* Message fields lang tabs
* ------------------------->
* - catch href on `.js-popup` element and open element with target id
* - close it with `.js-popupclose` inside or esc key
*/
document.addEventListener("click", (event) => {
	if (event.target && event.target.parentElement && event.target.parentElement.classList.contains("js-langtabsnav")) {
		const popupId = event.target.closest('figure').getAttribute('id')
		const index = Array.from(event.target.parentElement.parentElement.children).indexOf(event.target.parentElement)

		const langtabsList = doc.getElementById(popupId).getElementsByClassName('js-langtabslist')
		const langtabsListCurrent = langtabsList.item(index - 1)

		Array.from(langtabsList).forEach(function(element) {
			element.classList.add('hidden')
		})

		langtabsListCurrent.classList.remove('hidden')
	}
})


/* Popup
* ------------------------->
* - catch href on `.js-popup` element and open element with target id
* - close it with `.js-popupclose` inside or esc key
*/

const popups = doc.getElementsByClassName('js-popup')
const popupsTargets = doc.getElementsByClassName('popup')
const popupsClose = doc.getElementsByClassName('js-popupclose')
const popupBg = doc.getElementById('js-popupbg')

document.addEventListener("click", (event) => {
	if (event.target && event.target.classList.contains("js-popup")) {
		var target = event.target.getAttribute('href').replace('#', '')
		var targetEl = doc.getElementById(target)

		if (typeof(targetEl) != 'undefined' && targetEl != null){
			targetEl.classList.remove('popup--hide')
			doc.body.classList.add('popup-is-open')
		}

	}
})


document.addEventListener("click", (event) => {
	if (event.target && event.target.classList.contains("js-popupclose")) {
		var parent = event.target.closest('figure')

		parent.classList.add('popup--hide')
		doc.body.classList.remove('popup-is-open')
	}
})

doc.onkeydown = function(evt) {
    evt = evt || window.event;
    if (evt.keyCode == 27) {
    		Array.from(popupsTargets).forEach(function(element) {
    			element.classList.add('popup--hide')
    		})
        doc.body.classList.remove('popup-is-open')
    }
};

/* Datepicker
* ------------------------->
* - https://github.com/dbushell/Pikaday
*/
const datepickers = doc.getElementsByClassName('js-datepicker')

Array.from(datepickers).forEach(function(element) {
	var picker = new Pikaday({
		field: element,
		format: 'YYYY-MM-DD',
		i18n: {
		    previousMonth : 'Předchozí měsíc',
		    nextMonth     : 'Následující měsíc',
		    months        : ['Leden', 'Únor', 'Březen', 'Duben', 'Květen', 'Červen', 'Červenec', 'Srpen', 'Září', 'Říjen', 'Listopad', 'Prosinec'],
		    weekdays      : ['neděle', 'pondělí', 'úterý', 'středa', 'čtvrtek', 'pátek', 'sobota'],
		    weekdaysShort : ['Ne','Po','Út','St','Čt','Pá','So']
		}
	});
})

/* Tag input
* ------------------------->
*/
function buildTagsItem(label){
	return '<div class="labels__item"><span class="labels__item__label">' + label + '</span><div class="labels__item__remove"><div class="circle">✕</div></div></div>'
}

function buildTagsComponent(tagsArr){
	var returnStr = '<div class="labels form__attr__labels js-taginput-tags">';
	Array.from(tagsArr).forEach(function(tag) {
		returnStr = returnStr + buildTagsItem(tag);
	})
	var returnStr = returnStr + '<div class="labels__form"><input class="labels__form__field" type="text" placeholder="Hodnota"><button class="labels__form__submit js-taginput-submit" type="submit">&plus;</button></div></div>';

	return returnStr
}

function tagsStringToArray(string){
	return string.split(",").map(el => el.trim()).filter(tag => tag) || [];
}

function tagsArrayToString(arr){
	return arr.toString()
}

function initTagsComponent(){
	const taginputs = doc.getElementsByClassName('js-taginput')
	const tagsEls = doc.getElementsByClassName('js-taginput-tags')

	Array.from(tagsEls).forEach(function(element) {
		element.remove()
	})

	Array.from(taginputs).forEach(function(element) {
		const taginput = element
		const taginputParent = element.parentElement
		const tags = tagsStringToArray(taginput.value)
		taginputParent.insertAdjacentHTML('beforeend', buildTagsComponent(tags));
	})

	const tagremove = doc.getElementsByClassName('labels__item__remove')
	Array.from(tagremove).forEach(function(element) {
		element.addEventListener('click', function(){
			const remove = this.parentElement.children[0].innerHTML
			const parent = this.parentElement
			const taginput = this.closest('.form__input').children[0]
			const tags = tagsStringToArray(taginput.value)
			const updatedTags = tagsStringToArray(taginput.value)

			removeFromArray(updatedTags, remove)
			taginput.value = tagsArrayToString(updatedTags)
			parent.remove()
		})
	})

	const tagsubmit = doc.getElementsByClassName('js-taginput-submit')
	Array.from(tagsubmit).forEach(function(element) {
		element.addEventListener('click', function(e){
			const parent = this.parentElement
			const newTag = this.parentElement.children[0].value
			const taginput = this.closest('.form__input').children[0]
			const tags = tagsStringToArray(taginput.value)
			const updatedTags = tagsStringToArray(taginput.value)

			if(newTag != ""){
				updatedTags.push(newTag)
				taginput.value = tagsArrayToString(updatedTags)

				this.parentElement.children[0].value = ''

				initTagsComponent()
			}else{
				this.parentElement.children[0].focus()
			}

			e.preventDefault()
		})
	})

}

initTagsComponent()


/* Helper functions
* ------------------------->
*/
function removeFromArray(arr) {
    var what, a = arguments, L = a.length, ax;
    while (L > 1 && arr.length) {
        what = a[--L];
        while ((ax= arr.indexOf(what)) !== -1) {
            arr.splice(ax, 1);
        }
    }
    return arr;
}


/**
 * Count sms length and change element texts
 */
["change", "keyup", "paste", "input", "propertychange"].forEach(event => document.addEventListener(event, (event) => {
	if (event.target && event.target.classList.contains("sms-input")) {
		const result = SmsCounter.count(event.target.value)
		event.target.parentNode.querySelector('.sms-counter').textContent = result.remaining + ' znaků do konce ' + result.messages + '. SMS'
	}
}))

window.addEventListener('load', (event) => {
	document.querySelectorAll('.sms-input').forEach((input) => {
		const event = new Event('change', {bubbles: true})
		input.dispatchEvent(event)
	})
})
